import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { signOut as authSignOut } from "@homebound/auth-components";
import {
  ButtonMenu,
  ButtonModal,
  Css,
  Icon,
  IconButton,
  NavLink,
  Palette,
  px,
  useBreakpoint,
  useTestIds,
} from "@homebound/beam";
import { useCallback, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { HomeboundTradeLogo } from "src/components/HomeboundTradeLogo";
import { openZendesk } from "src/components/ZendeskWidget";
import { useFeatureFlag } from "src/contexts/FeatureFlagContext";
import { FeatureFlagType, UserDetailsFragment } from "src/generated/graphql-types";
import { MobileNav } from "src/routes/layout/MobileNav";
import { ImpersonateTradeUserModal } from "../auth/components/ImpersonateTradeUserModal";
import { accountsSettingsPath, projectPaths, purchaseOrdersPath, schedulePath } from "../routesDef";

interface ToolbarLink {
  // What is shown as a label
  label: string;
  // Which path or paths will activate the label
  path: string;
  // What path to redirect the user when clicking on the label
  value?: string;
  // Allow render of custom nav components like ButtonMenu
  customJSX?: ReactJSXElement;
  // Feature flag to show/hide the schedule nav/page
  featureFlag?: FeatureFlagType;
}

export function GlobalNav({ user }: { user: UserDetailsFragment }) {
  const { mdAndDown: mediumAndSmaller } = useBreakpoint();
  const [openMobile, setOpenMobile] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const testIds = useTestIds({}, "globalNav");
  const { featureIsEnabled } = useFeatureFlag();

  const toolbarLinks: ToolbarLink[] = [
    { label: "Purchase Orders", path: purchaseOrdersPath },
    { label: "Schedule", path: schedulePath },
    { label: "Invoices", path: projectPaths.invoices },
  ];

  const profileOptions = [
    {
      label: "Profile",
      onClick: "https://myaccount.google.com/personal-info",
    },
    {
      label: "Account Settings",
      onClick: accountsSettingsPath,
    },
  ];

  const showImpersonateTradeUserModal = useCallback((close: VoidFunction) => {
    return <ImpersonateTradeUserModal close={close} />;
  }, []);

  const signOut = useCallback(() => {
    authSignOut().catch(console.error);
    history.push("/login");
    window.location.reload();
  }, [history]);

  return (
    <nav
      css={Css.bgGray800.fs0.w100.sticky.top0.z999.h(px(topNavHeight)).df.aic.if(mediumAndSmaller).jcsb.$}
      {...testIds}
    >
      <div css={Css.mx3.$}>
        <div css={Css.df.fdr.$}>
          {mediumAndSmaller && (
            <MobileNav links={toolbarLinks} onSignOut={signOut} openState={[openMobile, setOpenMobile]} />
          )}
          {(!mediumAndSmaller || openMobile) && (
            <Link to={purchaseOrdersPath} {...testIds.homeLink} css={Css.if(mediumAndSmaller).ml2.$}>
              <HomeboundTradeLogo width={170} color={Palette.White} />
            </Link>
          )}
        </div>
      </div>

      {!mediumAndSmaller && (
        <div css={Css.df.aic.mx2.fg1.gap2.$}>
          {toolbarLinks
            .filter(({ featureFlag }) => !featureFlag || featureIsEnabled(featureFlag))
            .map(({ label, path, value = path, customJSX }) => {
              return (
                customJSX ?? (
                  <NavLink
                    key={value}
                    href={value}
                    label={label}
                    active={location.pathname.startsWith(path)}
                    variant="global"
                    {...testIds.link}
                  />
                )
              );
            })}
        </div>
      )}
      <div css={Css.bgGray800.mtPx(4).ml2.mr3.fs0.df.my1.aic.if(user?.isBeingImpersonated).gap2.$}>
        {
          <div css={Css.mr2.$}>
            <IconButton
              icon="helpCircle"
              color={Palette.Gray500}
              contrast
              inc={4}
              onClick={() => openZendesk(user.name, user.email)}
            />
          </div>
        }
        {user && !openMobile && (
          <ButtonMenu
            items={profileOptions}
            trigger={{ src: user.avatarUrl, name: user.name }}
            placement="right"
            persistentItems={[
              {
                label: "Sign Out",
                onClick: signOut,
              },
            ]}
          />
        )}
        {user?.isBeingImpersonated && (
          <ButtonModal
            variant="primary"
            content={showImpersonateTradeUserModal}
            trigger={{
              label: (
                <div {...testIds.impersonateUser}>
                  <Icon icon="userCircle" />
                </div>
              ),
            }}
          />
        )}
        {openMobile && <IconButton icon="x" color={Palette.White} contrast onClick={() => setOpenMobile(false)} />}
      </div>
    </nav>
  );
}

export const topNavHeight = 48;
