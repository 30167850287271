import {
  collapseColumn,
  column,
  Css,
  emptyCell,
  GridColumn,
  GridDataRow,
  GridTable,
  SelectField,
  simpleHeader,
  useBreakpoint,
  useSessionStorage,
} from "@homebound/beam";

import { snakeCase } from "change-case";
import { emptyCellDash, priceCell } from "src/components";
import {
  BillType,
  InvoicePage_BillLineItemsFragment,
  InvoicePage_TradeLineItemFragment,
} from "src/generated/graphql-types";
import { TableActions } from "src/routes/layout/TableActions";
import { foldEnum, formatList } from "src/utils";

enum ItemsGroupBy {
  Templates = "template",
  ItemCode = "itemCode",
}

type InvoiceTableProps = {
  lineItems: InvoicePage_BillLineItemsFragment[];
  enableProductConfigPlan: boolean;
  type: BillType;
  isTradePartnerCredit: boolean;
};

export function InvoiceTable({ lineItems, enableProductConfigPlan, type, isTradePartnerCredit }: InvoiceTableProps) {
  const { mdAndDown: mediumAndSmaller } = useBreakpoint();
  const itemTemplateItems = lineItems.map((li) => li.projectItem.itemTemplateItem).compact();
  const [templatesGroupBy, setTemplatesGroupBy] = useSessionStorage("templatesTable", ItemsGroupBy.ItemCode);
  const hasProratedLines =
    enableProductConfigPlan &&
    lineItems.some((bli) => bli.commitmentLineItem?.primaryBidContractLineItem?.prorations.nonEmpty);

  return (
    <div css={Css.os.mt6.$}>
      <div css={Css.df.jcsb.mb4.fdr.if(mediumAndSmaller).fdc.$}>
        <div css={Css.lgSb.if(mediumAndSmaller).mb2.$}>Completed Item Codes</div>
        <TableActions>
          <div>
            {!hasProratedLines && (
              <SelectField
                compact
                sizeToContent
                label="Group by"
                labelStyle={"inline"}
                options={[
                  ...(itemTemplateItems.nonEmpty ? [{ id: ItemsGroupBy.Templates, name: "Plan and Option" }] : []),
                  { id: ItemsGroupBy.ItemCode, name: "Item Code" },
                ]}
                getOptionValue={(o) => o.id}
                getOptionLabel={(o) => o.name}
                value={templatesGroupBy}
                onSelect={(gb) => gb && setTemplatesGroupBy(gb)}
              />
            )}
          </div>
        </TableActions>
      </div>
      <GridTable
        as="table"
        rows={rows(lineItems, templatesGroupBy, type, isTradePartnerCredit, enableProductConfigPlan)}
        columns={columns()}
        style={{ bordered: false, allWhite: true }}
        rowStyles={{ header: { cellCss: Css.gray600.$ }, total: { cellCss: Css.smSb.mt2.$ } }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type GroupRow = { kind: "group"; data: string | InvoicePage_TradeLineItemFragment[] };
type ItemRow = { kind: "item"; data: InvoicePage_BillLineItemsFragment };
type TotalRow = { kind: "total"; data: { valueInCents: number; name: string } };
export type Row = HeaderRow | GroupRow | ItemRow | TotalRow;

function rows(
  lineItems: InvoicePage_BillLineItemsFragment[],
  groupedBy: ItemsGroupBy,
  type: BillType,
  isTradePartnerCredit: boolean,
  enableProductConfigPlan: boolean,
): GridDataRow<Row>[] {
  const [subtotal, tax, total] = [
    lineItems.sum((li) => li.taxableAmountInCents),
    lineItems.sum((li) => li.taxInCents),
    lineItems.sum((li) => li.amountInCents),
  ].map((amount) => (isTradePartnerCredit ? -amount : amount));
  const totals = [totalsRow({ name: "Total Amount Due", valueInCents: total })];
  if (tax !== 0) {
    totals.unshift(
      totalsRow({ name: "Subtotal", valueInCents: subtotal }),
      totalsRow({ name: "Sales Tax", valueInCents: tax }),
    );
  }

  if (type === BillType.Deposit) return [simpleHeader, ...groupByDeposit(lineItems), ...totals];
  const [drawLines, nonDrawLines] = lineItems.partition((bli) => !!bli.commitmentDraw);
  const [proratedLines, otherLines] = nonDrawLines.partition(
    (bli) => enableProductConfigPlan && !!bli.commitmentLineItem?.primaryBidContractLineItem?.prorations,
  );
  return [
    simpleHeader,
    ...groupByDraws(drawLines),
    ...groupByProratedLines(proratedLines),
    ...foldEnum(groupedBy, {
      template: groupByTemplate(otherLines),
      itemCode: groupByItemCode(otherLines),
    }),
    ...totals,
  ];
}

function totalsRow(data: { valueInCents: number; name: string }) {
  return { kind: "total" as const, id: snakeCase(data.name), data };
}

function groupByTemplate(blis: InvoicePage_BillLineItemsFragment[]): GridDataRow<Row>[] {
  const groupedbyTemplates = blis.groupBy((li) => li.projectItem.baseOrOptionName);
  return Object.entries(groupedbyTemplates).map(([templateName, clis]) => {
    return {
      kind: "group" as const,
      id: templateName,
      data: templateName,
      pin: templateName.includes("Base") ? { at: "first" as const, filter: true } : undefined,
      children: clis.map((cli) => ({
        kind: "item" as const,
        id: cli.id,
        data: cli,
      })),
    };
  });
}

function groupByItemCode(bLineItems: InvoicePage_BillLineItemsFragment[]): GridDataRow<Row>[] {
  if (bLineItems.isEmpty) return [];
  const groupedItems = bLineItems.groupBy((bli) => bli.projectItem.item.costCode.displayName);
  return groupedItems.toEntries().map(([itemCode, blis]) => {
    return {
      kind: "group" as const,
      id: itemCode,
      data: blis.first?.projectItem.item.costCode.displayName ?? "-",
      children: blis.map((li) => ({
        kind: "item" as const,
        id: li.id,
        data: li,
      })),
    };
  });
}

function groupByProratedLines(bLineItems: InvoicePage_BillLineItemsFragment[]): GridDataRow<Row>[] {
  if (bLineItems.isEmpty) return [];
  // Group by trade line item
  const groupedItems = bLineItems.groupBy(
    (bli) =>
      bli.commitmentLineItem?.primaryBidContractLineItem?.prorations.map((li) => li.tradeLineItem.id).join("") ?? "",
  );
  return groupedItems.toEntries().map(([tradeId, blis]) => {
    const tradeTotal = blis.sum((li) =>
      li.bill.isTradePartnerCredit ? -li.taxableAmountInCents : li.taxableAmountInCents,
    );
    return {
      kind: "group" as const,
      id: tradeId,
      data:
        bLineItems.first?.commitmentLineItem?.primaryBidContractLineItem?.prorations.map((proration) => ({
          ...proration.tradeLineItem,
          totalCostInCents: tradeTotal,
        })) ?? [],
      children: blis.map((li) => ({ kind: "item" as const, id: li.id, data: li })),
    };
  });
}

function groupByDeposit(blis: InvoicePage_BillLineItemsFragment[]): GridDataRow<Row>[] {
  if (blis.isEmpty) return [];
  const costChangeInCents = blis.sum((li) => li.commitmentLineItem?.costChangeInCents ?? 0);
  const taxableAmountInCents = blis.sum((li) => li.taxableAmountInCents);
  const depositInPercentage = (taxableAmountInCents / costChangeInCents) * 100;
  return [
    {
      kind: "group" as const,
      id: blis.first?.id ?? "",
      data: [
        {
          productOffering: { displayName: `${depositInPercentage}% Deposit` },
          totalCostInCents: taxableAmountInCents,
        },
      ] as InvoicePage_TradeLineItemFragment[],
    },
  ];
}

function groupByDraws(bLineItems: InvoicePage_BillLineItemsFragment[]): GridDataRow<Row>[] {
  if (bLineItems.isEmpty) return [];
  // Group by commitment draw
  const groupedItems = bLineItems.groupBy((bli) => bli.commitmentDraw?.id ?? "");
  return groupedItems.toEntries().flatMap(([drawId, blis]) => {
    const commitmentDraw = blis.first?.commitmentDraw;
    return {
      kind: "group" as const,
      id: drawId,
      data: [
        {
          productOffering: { displayName: `Draw: ${commitmentDraw!.task.name}` },
          totalCostInCents: blis.sum((bli) => bli.taxableAmountInCents),
        },
      ] as InvoicePage_TradeLineItemFragment[],
    };
  });
}

function columns(): GridColumn<Row>[] {
  return [
    collapseColumn<Row>({ item: emptyCell }),
    column<Row>({
      id: "po",
      header: "PO #",
      group: (data) => ({
        content: Array.isArray(data) ? formatList(data.map((data) => data.productOffering.displayName)) : data,
        colspan: 3,
      }),
      item: ({ commitmentLineItem }) => commitmentLineItem?.owner.accountingNumber ?? emptyCellDash,
      total: emptyCell,
      w: "250px",
    }),
    column<Row>({
      id: "qty",
      header: "QTY",
      group: emptyCell,
      item: ({ commitmentLineItem }) =>
        commitmentLineItem?.newQuantity || commitmentLineItem?.quantity || emptyCellDash,
      total: emptyCell,
    }),
    column<Row>({
      id: "uom",
      header: "UoM",
      group: emptyCell,
      item: ({ projectItem: { itemTemplateItem, unitOfMeasure } }) =>
        itemTemplateItem?.unitOfMeasure.abbreviation.toLowerCase() ?? unitOfMeasure.abbreviation.toLowerCase(), // use pi if no iti
      total: emptyCell,
      w: "100px",
    }),
    column<Row>({
      id: "itemCode",
      header: "Item Code",
      group: emptyCell,
      item: ({ projectItem: { itemTemplateItem, item } }) => itemTemplateItem?.item.fullCode ?? item.fullCode,
      total: emptyCell,
      w: "150px",
    }),
    column<Row>({
      id: "description",
      header: "Description",
      group: emptyCell,
      item: ({ projectItem: { itemTemplateItem, name } }) => itemTemplateItem?.name ?? name,
      total: ({ name }) => name,
      w: "200px",
    }),
    column<Row>({
      id: "unitCost",
      header: "Unit Cost",
      group: emptyCell,
      item: ({ commitmentLineItem }) => {
        const { quantity, newQuantity, newTaxableCostInCents, primaryBidContractLineItem } = commitmentLineItem ?? {};
        return commitmentLineItem?.projectItem.unitOfMeasure.useQuantity &&
          !primaryBidContractLineItem?.prorations.nonEmpty
          ? priceCell({ valueInCents: (newTaxableCostInCents || 0) / (newQuantity || quantity || 1) })
          : emptyCellDash;
      },
      total: emptyCell,
      w: "140px",
    }),
    column<Row>({
      id: "totalCost",
      header: "Total Cost",
      // Show cost for each trade line item
      // Labor tasks associated with multiple trade line items will have their costs accounted for in each individual trade line
      group: (data) =>
        Array.isArray(data) && data.length === 1 ? priceCell({ valueInCents: data[0].totalCostInCents }) : emptyCell,
      item: ({ taxableAmountInCents, bill, commitmentLineItem }) =>
        commitmentLineItem?.primaryBidContractLineItem?.prorations.nonEmpty
          ? emptyCell
          : priceCell({ valueInCents: bill.isTradePartnerCredit ? -taxableAmountInCents : taxableAmountInCents }),
      total: ({ valueInCents }) => priceCell({ valueInCents }),
      w: "160px",
    }),
  ];
}
